<template>
  <div class="imagePreview">
    <div class="imageContent">
      <img @click="clickImage" class="img-object" :src="image">
    </div>
    <svg-icon @click.stop="close" width="20px" height="20px" name="guanbi" style="position: absolute;top: -10px;left: -10px"></svg-icon>
  </div>
</template>

<script>
export default {
  props: {
    image: {
      type: String,
      default: ''
    }
  },
  name: 'imagePreview',
  methods:{
    clickImage:function() {
      this.$emit('clickImage', {});
    },
    close:function() {
      this.$emit('closePreview', {});
    },
  }
}
</script>

<style lang="scss" scoped>
@import "@styles/variables.scss";

.imagePreview {
  width: 80px;
  height: 160px;
  background-color: $color2;
  border-radius: 10px;

  .imageContent {
    width: calc(100% - 5px);
    height: calc(100% - 5px);
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 10px;
    background-color: white;

    /* 图片自适应的样式 */
    .img-object {
      object-fit: scale-down;
      width: calc(100% - 5px);
      height: calc(100% - 5px);
      position: relative;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border-radius: 10px;
      overflow: hidden;
    }

  }

}

</style>
