<template>
  <div class="deviceManagerSearch" id="deviceManagerSearch">
    <div class="searchView">
      <el-input
          @change="loadTableData(true, true)"
          size="medium"
          class="search"
          placeholder="请输入内容"
          prefix-icon="el-icon-search"
          v-model="query"
      >

      </el-input>
      <div class="other">
        取消
      </div>
    </div>

    <el-collapse style="z-index: 9999" v-model="activeNames">
      <el-collapse-item title="历史记录" name="1">
        <div class="historyView">
          <el-tag
              style="margin: 5px;border-radius: 16px"
              v-for="(tag, index) in historyList"
              :key="tag.name"
              closable
              @click="selectTag(tag)"
              @close="handleClose(tag, index)"
              type="info"
          >
            {{ tag.name }}
          </el-tag>
        </div>
      </el-collapse-item>
    </el-collapse>

    <div class="deviceView">
      <device-item @deviceDetail="deviceDetail" v-for="(item, index) in tableData" :key="index" :data-info="item"
      ></device-item>
    </div>

    <h-stack style="height: 40px;background-color: white">
      <el-pagination
          @current-change="currentChange"
          :page-size="pageSize"
          :pager-count="5"
          :current-page="page"
          layout="prev, pager, next"
          :total="totalCount"
      >
      </el-pagination>
    </h-stack>
    <image-preview @closePreview="closeImage" v-show="showImage" class="preview" :image="image"></image-preview>

  </div>
</template>

<script>
import { Loading } from 'element-ui'
import DeviceItem from '@/views/deviceManager/deviceList/deviceItem'
import HStack from '@/components/hStack/hStack'
import ImagePreview from '@/components/imagePreview/imagePreview'

export default {
  name: 'deviceManagerSearch',
  components: { ImagePreview, HStack, DeviceItem },
  data() {
    return {
      showImage: false,
      image: '',
      loadingView: null,
      loading: false,
      page: 1,
      pageSize: 30,
      totalCount: 0,
      activeNames: [],
      tableData: [],
      query: '',
      historyList: []
    }
  },
  watch: {
    loading: {
      handler(value) {
        if (value) {
          this.startLoading()
        } else {
          if (this.loadingView == null) {
            return
          }
          this.loadingView.close()
        }
      },
      immediate: true,
      deep: true
    }
  },
  beforeDestroy() {
    this.removeLocalStorageEvent(this.updateImage)
  },
  mounted() {
    this.getHistory()
    this.addLocalStorageEvent(this.updateImage)
    this.updateImage()
  },
  methods: {
    closeImage: function() {
      this.image = ''
      this.showImage = false
      this.saveImage('')
    },
    updateImage: function() {
      this.getImage().then(res => {
        if (res.result == false) {
          this.showImage = false
          this.image = ''
          return
        }
        this.showImage = true
        this.image = res.image
      })
    },
    selectTag: function(item) {
      this.query = item.name
      this.loadTableData(true, true)
    },
    handleClose: function(item, index) {
      this.historyList.splice(index, 1)
      localStorage.setItem('historySearch', JSON.stringify(this.historyList))
    },
    reset: function() {
      this.page = 1
      this.pageSize = 30
    },
    deviceDetail: function(res) {
      if (this.$valueIsExist(res, 'id') == false) {
        return
      }
      let info = {
        id: res['id']
      }
      this.$push('deviceDetailManager', info)
    },
    currentChange: function(index) {
      this.page = index
      this.loadTableData(false, true)
    },
    startLoading: function() {
      let dom = document.getElementById('deviceManagerSearch')
      if (typeof dom == 'undefined' || dom == null) {
        return
      }
      this.loadingView = Loading.service({ target: dom })
    },
    updateHistory: function() {
      if (this.query == '') {
        return
      }
      let maxLength = 20
      for (let i = 0; i < this.historyList.length; i++) {
        let info = this.historyList[i]
        if (info.name == this.query) {
          return
        }
      }
      if (this.historyList.length >= maxLength) {
        let count = this.historyList.length - maxLength + 1
        this.historyList.splice(0, count)
      }
      this.historyList.push({ name: this.query })
      localStorage.setItem('historySearch', JSON.stringify(this.historyList))
    },
    getHistory: function() {
      if (this.$valueIsExist(localStorage, 'historySearch') == false) {
        return
      }
      try {
        this.historyList = JSON.parse(localStorage['historySearch'])
      } catch (e) {

      }
    },
    loadTableData: function(reset, loading) {

      this.updateHistory()

      if (reset) {
        this.reset()
      }
      if (loading) {
        this.loading = true
      }

      let loadData = () => {
        let tenantId = ''

        let userInfo = this.$store.getters.userInfo
        if (this.$valueIsExist(userInfo, 'tenantId')) {
          tenantId = userInfo['tenantId']
        }

        let getPost = {
          deleted: 0,
          deviceType: '',
          query: this.query,
          page: this.page,
          pageSize: this.pageSize,
          order: '',
          orderType: '',
          tenantId: tenantId,
          isTenantIdChild: 1,
          categoryId: ''
        }
        this.$api.assetModule.getDeviceList(getPost).then(res => {
          if (res.code == 200) {
            this.tableData = res.data
            this.totalCount = res.totalCount
          }
          this.loading = false

        }).catch(e => {
          this.loading = false
        })
      }

      loadData()

    }
  }
}
</script>

<style lang="scss" scoped>
@import "@styles/variables.scss";

.deviceManagerSearch {
  width: 100%;
  height: calc(100%);
  background-color: white;
  position: fixed;

  .deviceView {
    margin-top: 50px;
    overflow: scroll;
    width: 100%;
    height: calc(100% - 140px);
  }

  .searchView {
    width: 100%;
    height: 50px;

    .search {
      margin-left: 10px;
      width: calc(100% - 60px);
      position: relative;
      top: 50%;
      transform: translate(0px, -50%);
      float: left;
    }

    .other {
      height: 28px;
      width: 28px;
      line-height: 28px;
      float: right;
      position: relative;
      top: 50%;
      right: 10px;
      transform: translate(0px, -50%);

    }

  }

  .historyView {
    width: calc(100% - 20px);
    margin-left: 10px;
    background-color: $background_color2;

  }


}


::v-deep .search .el-input__inner {
  border-radius: 20px;
}


::v-deep .el-collapse-item .el-collapse-item__header {
  margin-left: 20px;
  border-bottom: none;
}


::v-deep .el-collapse {
  border-top: none;
  border-bottom: none;
  position: fixed;
  width: 100%;
}

::v-deep .el-collapse-item__wrap {
  background-color: $background_color2;
}

.preview {
  position: absolute;
  right: 10px;
  bottom: 10px;
}

</style>
