<template>
  <div class="deviceItem" @click="deviceDetail">
    <div class="deviceContentView">
      <div class="imageContent">
        <div class="content" v-if="image != ''">
          <img class="img-object" :src="image" alt=""/>
        </div>
        <div v-if="image != ''"
             style="margin-top: 5px;width: 100%;height: 20px;font-size: 11px;text-align: center;line-height: 20px"
        >
          {{ imgText }}
        </div>
        <div class="noContent" v-if="image == ''">
          暂无图片
        </div>
      </div>
      <div class="contentMsg">
        <div class="contentTextView">
          <div class="content">
            {{ name }}
          </div>
          <div class="subContent">
            {{ tenantName }}
          </div>
          <div class="subContent">
            卡片号:{{ label }}
          </div>
          <div class="subContent">
            {{$VUEAPPDEVICECODE()}}:{{ code }}
          </div>
        </div>
        <div class="allowView">
          <svg-icon width="100%" height="100%" name="arrow-right"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'deviceItem',
  components: {

  },
  props: {
    dataInfo: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  computed: {
    imgText() {
      if (this.imageList.length == 0) {
        return ''
      }
      let item = this.imageList[this.imageList.length - 1];
      return item.remark
    },
    image() {
      if (this.imageList.length == 0) {
        return ''
      }
      let item = this.imageList[this.imageList.length - 1];
      return item.url + '&scala=0.3'
    },
    imageList() {
      this.imageIndex = 0
      if (this.$valueIsExist(this.dataInfo, 'photos') == false) {
        return []
      }
      return this.dataInfo['photos']
    },
    name() {
      if (this.$valueIsExist(this.dataInfo, 'name') == false) {
        return 0
      }
      return this.dataInfo['name']
    },
    tenantName() {
      if (this.$valueIsExist(this.dataInfo, 'tenantName') == false) {
        return ''
      }
      return this.dataInfo['tenantName']
    },
    label() {
      if (this.$valueIsExist(this.dataInfo, 'label') == false) {
        return ''
      }
      return this.dataInfo['label']
    },
    code() {
      if (this.$valueIsExist(this.dataInfo, 'code') == false) {
        return ''
      }
      return this.dataInfo['code']
    }
  },
  data() {
    return {
      imageIndex: 0
    }
  },
  mounted() {
    this.$nextTick(() => {

    })
  },
  methods: {
    deviceDetail: function() {
      this.$emit('deviceDetail', this.dataInfo)
    }
  }
}
</script>


<style lang="scss" scoped>
@import "@styles/variables.scss";

.deviceItem {
  background-color: white;

  .deviceContentView {
    width: calc(100% - 10px);
    height: 100px;
    margin-left: 10px;
    margin-bottom: 5px;

    .imageContent {
      float: left;
      width: 96px;
      height: 96px;
      top: 50%;
      position: relative;
      transform: translate(0px, -50%);

      .noContent {
        transform: translate(0px, 3px);
        width: 90px;
        height: 90px;
        border-radius: 10px;
        text-align: center;
        line-height: 90px;
        font-size: 13px;
        background-color: $background_color1;
        color: $color3;
      }

      .content {
        transform: translate(10px, 3px);
        width: 70px;
        height: 70px;
        border-radius: 10px;
        text-align: center;
        overflow: hidden;

        /* 图片自适应的样式 */
        .img-object {
          object-fit: cover;
          width: 100%;
          height: 100%;
        }


      }

    }

    .contentMsg {
      float: right;
      width: calc(100% - 98px);
      height: 96px;
      top: 50%;
      position: relative;
      transform: translate(0px, -50%);

      .contentTextView {
        width: calc(100% - 52px);
        height: 100%;
        float: left;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;


        .subContent {
          font-size: 12px;
          margin-left: 10px;
          overflow:hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          color: $color2;
        }

        .content {
          font-size: 16px;
          margin-left: 10px;
          overflow:hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }

      .allowView {
        margin-left: 10px;
        margin-right: 10px;
        float: right;
        width: 15px;
        height: 15px;
        position: relative;
        top: 50%;
        transform: translate(0px, -50%);

      }

    }


  }
}

</style>
